<template>
    <div class="article-content">
        <el-scrollbar class="article-wrap">
            <div class="top-tab">
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb" v-if="!type">
                    <el-breadcrumb-item>网络客服服务</el-breadcrumb-item>
                    <el-breadcrumb-item>客户策略与分析</el-breadcrumb-item>
                    <el-breadcrumb-item>查看策略</el-breadcrumb-item>
                </el-breadcrumb>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb" v-else>
                    <el-breadcrumb-item>实操训练</el-breadcrumb-item>
                    <el-breadcrumb-item>网络客户服务</el-breadcrumb-item>
                    <el-breadcrumb-item>查看详情</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="btn-box">
                    <el-button type="primary" @click="goBack">返回</el-button>
                </div>
            </div>
            <div class="main-content">
                <div class="main-wrap">
                    <div class="top-title">{{articleInfo.title}}</div>
                    <div class="top-author">{{articleInfo.author}}</div>
                    <div class="top-content" v-html="articleInfo.text"></div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {see_drill_result, see_exam_result} from '@/utils/apis'

    export default {
        name: "articleDetail",
        data() {
            return {
                id: Number(this.$route.query.id) || null,
                articleInfo: {},
                platform: this.$route.meta.type,
                type: this.$route.query.type,
            }
        },
        created() {
            if (this.id !== null) {
                this.getEditList();
            }
        },
        methods: {
            getEditList() {
                let param = {
                    id: this.id
                }
                if (this.platform == 0) {
                    see_drill_result(param).then(res => {
                        this.articleInfo = res.data;
                    }).catch(err => {
                        console.error(err)
                    })
                } else {
                    see_exam_result(param).then(res => {
                        this.articleInfo = res.data;
                    }).catch(err => {
                        console.error(err)
                    })
                }
            },
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
    .article-content {

        .article-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;

            ::v-deep > .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    background: #ffffff;
                }
            }
        }

        .top-tab {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding: 0 20px 15px;

            ::v-deep .genera-breadcrumb::before {
                height: 0;
            }

            .genera-breadcrumb {
                display: flex;
                align-items: center;
            }
        }

        .main-content {
            margin-top: 20px;
            flex: 1;
            height: 1%;

            .main-wrap {
                width: 900px;
                margin: 0 auto;
                height: 100%;

                .top-title {
                    text-align: center;
                    font-size: 20px;
                    color: #333333;
                }

                .top-author {
                    margin-top: 12px;
                    text-align: center;
                    font-size: 14px;
                    color: #666666;
                }

                .top-content {
                    margin-top: 20px;

                    ::v-deep p {
                        img {
                            max-width: 900px;
                        }
                    }
                }
            }
        }
    }
</style>